import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { sizes, theme } from "@styles/Theme";
import { Box, Button, Container, styled } from "@mui/material";
import LogoElement from "../LogoElement";
import { useRouter } from "next/router";
import Link from "next/link";
import main from "@public/cms/main.json";
import selectText from "helpers/selectText";
import { MainText } from "@public/cms/types";

export default function Navbar() {
  const router = useRouter();
  const language = router.locale;
  const mainText = selectText(main, language as "nl" | "fr") as MainText;

  {
    const languageButtons = (
      <div style={{ color: "white" }}>
        <ul>
          <li
            style={{
              display: "inline",
              marginLeft: "3px",
              marginRight: "3px",
              cursor: "pointer",
            }}
          >
            <Link locale="nl" href="">
              <span style={{ fontFamily: "GraphikMedium" }}>NL</span>
            </Link>
          </li>
          <li
            style={{
              display: "inline",
              borderLeft: `1px solid white`,
              paddingLeft: "3px",
              paddingRight: "3px",
              cursor: "pointer",
            }}
          >
            <Link locale="fr" href="">
              <span style={{ fontFamily: "GraphikMedium" }}>FR</span>
            </Link>
          </li>
        </ul>
      </div>
    );
    return (
      <>
        <AppBar
          color={"primary"}
          elevation={0}
          sx={{
            height: sizes.navBarHeight,
          }}
        >
          <Toolbar
            sx={{
              height: "100%",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Link href={"/"}>
                  <LogoElement
                    onClick={() => {
                      if ("/" == router.asPath) {
                        router.reload();
                      }
                    }}
                    variant="white"
                  />
                </Link>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    display: { xs: "none", sm: "inherit" },
                    textColor: "#000000",
                    fontFamily: "GraphikSemiBold",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: { sm: "12px", md: "16px" },
                    spacing: "1px",
                  }}
                  target={"_blank"}
                  href={mainText.toPartenaProfessionalLink}
                >
                  {mainText.toPartenaProfessional}
                </Button>
                {languageButtons}
                {/* custom dropdown input for localization => has it's own look compared to the form inputs */}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
