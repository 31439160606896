import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";

export const sizes = {
  navBarHeight: "120px",
};

export const colors = {
  background: {
    default: "#F9FBFD",
    paper: "#fff",
  },
  primary: {
    main: "#080070", // this is royal blue from partena.
    dark: "#2738C7",
    light: "#0D03CA", // light blue by partena
    contrastText: "#fff",
  },
  secondary: {
    main: "#F74B64", // this is partena's secondary red.
    dark: "#F72161", //
    light: "#60B5E6", // this is cyan by partena
    contrastText: "#fff",
  },
  error: {
    main: "#D2112E", // partena's red for errors
  },
  info: {
    main: "#53ac6c",
  },
  success: {
    main: "#3AD06A", // partena's Green for sucess.
    dark: "209E3C",
    light: "#9EF09C",
  },
  grey: {
    main: "#706F6F", // partena's dark grey
    "500": "#666666", // normal grey
    light: "#9D9D9C", // partena's light grey
  },
  component: {
    paperBorderColor: "#E7EEF8",
    inputBorderColor: "#B4B4B4",
    inputBorderColorHover: "#080070",
  },
};

export let theme: Theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      "GraphikSemibold",
      "GraphikMedium",
      "Barlow Semi Condensed",
      "sans-serif",
      "-apple-system",
    ].join(","),
    fontSize: 16,
    h1: { fontSize: "2rem", lineHeight: "1.4", fontFamily: "GraphikSemibold" },
    h2: {
      fontSize: "1.875rem",
      lineHeight: "1.4",
      fontFamily: "GraphikSemibold",
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "1.4",
      fontFamily: "GraphikSemibold",
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: "1.4",
      fontFamily: "GraphikSemibold",
    },
    h5: {
      fontSize: "1.125rem",
      lineHeight: "1.4",
      fontFamily: "GraphikSemibold",
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "1.4",
      fontFamily: "GraphikSemibold",
    },
    // used for titles in the forms
    subtitle1: {
      fontSize: "0.625rem",
      fontFamily: "GraphikSemibold",
      fontWeight: 600,
      letterSpacing: "0.7px",
    },
    // define a subtitle2
    subtitle2: {
      fontSize: "0.9rem",
      fontFamily: "Barlow Semi Condensed",
    },
    //regular body text
    body1: {
      fontSize: "1rem",
      fontFamily: "Barlow Semi Condensed",
      fontWeight: 500,
      color: colors.grey[500],
    },
    body2: {
      fontSize: "0.8rem",
      fontFamily: "Barlow Semi Condensed",
      fontWeight: 500,
      color: colors.grey[500],
    },
  },

  //---------- Global colors ----------
  palette: {
    background: colors.background,
    primary: colors.primary,
    secondary: colors.secondary,
    error: colors.error,
    info: colors.info,
    success: colors.success,
    grey: colors.grey,
  },

  shape: {
    borderRadius: 0,
  },

  //---------- Global components styling ----------
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: "100vh",
          "&::nth-of-type(1)": {
            minHeight: "100vh",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      styleOverrides: {
        root: {
          variantMapping: {
            subtitle1: "p",
            subtitle2: "p",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 5,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          paddingLeft: 10,
        },
      },
    },
    //---------- Navbar styling ----------
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: "none",
          borderBottom: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          borderRight: "none",
        },
        paper: {
          border: "none",
          borderRight: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    //---------- Global button styling ----------
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: "Barlow Semi Condensed",
          textTransform: "none",
          backgroundColor: "#FFFFFF",
          //border: "1px solid",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "medium",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          padding: "12px 24px",
          height: 48,
          borderRadius: "0px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlined: {
          border: `2px solid`,
          ":hover": {
            border: `2px solid`,
          },
        },
        contained: {
          border: "none",
        },
      },
    },

    //---------- General UI div elements - paper, card, etc ----------
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.component.paperBorderColor}`,
          boxShadow: "none",
        },
      },
    },

    // ---------- Global form input styling ----------

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "4px 5px",
          backgroundColor: "#FFFFFF",
        },
        root: {
          color: colors.grey[500],
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.component.inputBorderColor}`,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.primary.dark}`,
            },
          },
          "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.component.inputBorderColorHover}`,
            },
          },
          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.error.main} !important`,
            },
          },
        },
      },
    },
    //MuiButtonBase-root-MuiToggleButton-root.Mui-selected:
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiToggleButtonGroup-grouped": {
            boxShadow: "none",
            textTransform: "none",
            padding: 2,

            borderRadius: "0px",
            "&:hover": {
              boxShadow: "none",
            },
            border: `1px solid ${colors.component.inputBorderColor}`,
          },

          "&.Mui-selected": {
            border: `1px solid ${colors.primary.dark} !important`,
            backgroundColor: `${colors.secondary.light} !important`,
            color: "#FFFFFF !important",
            zIndex: 1,
          },
          "&.MuiToggleButtonGroup-grouped:hover": {
            border: `1px solid ${colors.primary.dark} !important`,

            zIndex: 1,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${colors.component.inputBorderColor}`,
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);
