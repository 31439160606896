import { Paper, Typography, TypographyProps } from "@mui/material";
import { theme } from "./Theme";

const headingStyles = {
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3),
  color: "secondary",
};

const h1 = (props: TypographyProps) => (
  <Typography
    variant="h1"
    {...props}
    paragraph
    style={headingStyles}
    color="primary"
  />
);
const h2 = (props: TypographyProps) => (
  <Typography
    variant="h2"
    {...props}
    paragraph
    style={headingStyles}
    color="primary"
  />
);
const h3 = (props: TypographyProps) => (
  <Typography
    variant="h3"
    {...props}
    paragraph
    style={headingStyles}
    color="primary"
  />
);
const h4 = (props: TypographyProps) => (
  <Typography variant="h4" {...props} paragraph style={headingStyles} />
);
const h5 = (props: TypographyProps) => (
  <Typography variant="h5" {...props} paragraph style={headingStyles} />
);
const h6 = (props: TypographyProps) => (
  <Typography variant="h6" {...props} paragraph style={headingStyles} />
);
const p = (props: TypographyProps) => (
  <Typography variant="body1" {...props} paragraph />
);

const code = (props: any) => (
  <Paper
    style={{
      backgroundColor: theme.palette.grey[900],
      color: "white",
      padding: theme.spacing(3),
      overflow: "auto",
    }}
    {...props}
  ></Paper>
);

const inlineCode = (props: any) => (
  <span
    style={{
      color: theme.palette.primary.dark,
      fontFamily: "monospace",
      backgroundColor: theme.palette.grey[200],
      borderRadius: 3,
      verticalAlign: "center",
      paddingLeft: "5px",
      paddingRight: "5px",
      display: "inline-flex",
      alignItems: "center",
    }}
    {...props}
  ></span>
);

const mdxComponents = {
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  h5: h5,
  h6: h6,
  p: p,
  code: code,
  inlineCode: inlineCode,
};

export default mdxComponents;
