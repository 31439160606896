import OfflineBoltRoundedIcon from "@mui/icons-material/OfflineBoltRounded";
import {
  Box,
  Button,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import footerJson from "@public/cms/footer.json";
import { FooterText } from "@public/cms/types";
import selectText from "helpers/selectText";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";

export default function Footer(props: {
  setCookieModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const language = router.locale;
  const footerText = selectText(
    footerJson,
    language as "nl" | "fr"
  ) as FooterText;

  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
  }));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1, sm: 1 },
          justifyContent: {
            xs: "center",
            sm: "space-around",
            md: "space-between",
          },
          alignItems: { xs: "center" },
          paddingLeft: { md: "64px" },
          paddingRight: { md: "64px" },
          mb: 1.5,
        }}
      >
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Typography>© 2024 Partena Professional</Typography>
          <Box sx={{ display: "flex" }}>
            <OfflineBoltRoundedIcon />
            <Typography>
              <MuiLink
                target="_blank"
                rel="noopener"
                href="https://www.aeco.cloud"
                color="inherit"
                underline="hover"
              >
                Powered by Aeco
              </MuiLink>
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <ColorButton
            variant="contained"
            sx={{
              height: "auto",
              textColor: "#000000",
              fontSize: "13px",
              spacing: "1px",
              p: 1,
            }}
            onClick={() => props.setCookieModalOpen(true)}
          >
            {footerText.cookieButton}
          </ColorButton>
          <Typography>
            <MuiLink
              target="_blank"
              rel="noopener"
              href={language == "fr" ? "/fr/cookiePolicy" : "/cookiePolicy"}
              color="inherit"
              underline="hover"
            >
              {footerText.cookiePolicy}
            </MuiLink>
          </Typography>
          <Typography>
            <MuiLink
              target="_blank"
              rel="noopener"
              href={language == "fr" ? "/fr/disclaimer" : "/disclaimer"}
              color="inherit"
              underline="hover"
            >
              {footerText.disclaimerFooter}
            </MuiLink>
          </Typography>
          {/*<Typography>Disclaimer</Typography>*/}
          <Typography>
            <MuiLink
              target="_blank"
              rel="noopener"
              href={footerText.privacyLink}
              color="inherit"
              underline="hover"
            >
              {footerText.privacy}
            </MuiLink>
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
