import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Container,
  Paper,
  Grid,
  Typography,
  IconButton,
  styled,
  Button,
  Stack,
  Link as MuiLink,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import LogoElement from "./LogoElement";
import { useRouter } from "next/router";
import selectText from "helpers/selectText";
import { CookieText } from "@public/cms/types";
import cookieText from "@public/cms/cookies.json";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import CheckBoxInput from "./form/inputs/CheckBoxInput";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Cookies from "js-cookie";
import { theme } from "@styles/Theme";

type CookieForm = {
  needed: true;
  performance: boolean;
  functional: boolean;
  targetAudiance: boolean;
  socialMedia: boolean;
};

export default function CookieModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const language = router.locale;
  const text = selectText(cookieText, language as "nl" | "fr") as CookieText;

  const preferenceCookie = Cookies.get("OptanonToolsConsent");
  let defaults: CookieForm = {
    needed: true,
    performance: false,
    functional: false,
    socialMedia: false,
    targetAudiance: false,
  };
  if (preferenceCookie) {
    //console.log("cookieValue: ", JSON.parse(preferenceCookie));
    defaults = { ...JSON.parse(preferenceCookie) };
  }

  const form = useForm<CookieForm>({ defaultValues: defaults });

  const handleAcceptAll = () => {
    Cookies.set(
      "OptanonToolsConsent",
      JSON.stringify({
        needed: true,
        performance: true,
        functional: true,
        targetAudiance: true,
        socialMedia: true,
      })
    );
    props.setOpen(false);
  };

  const handleCookieSubmit: SubmitHandler<CookieForm> = (data) => {
    // console.log("submitted: ", data);
    Cookies.set("OptanonToolsConsent", JSON.stringify(data));
    props.setOpen(false);
  };

  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
  }));
  return (
    <Dialog
      fullScreen={fullscreen}
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-cookies"
      aria-describedby="modal-modal-cookie-policy"
    >
      <Paper
        square
        sx={{
          maxWidth: fullscreen ? "xs" : "lg",
          maxHeight: 950,
          backgroundColor: "#F2F2F2",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container
          maxWidth={fullscreen ? "xs" : "md"}
          sx={{ m: fullscreen ? 0 : 2.5, mt: 4 }}
        >
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
            marginBottom={2}
          >
            <LogoElement variant="blue" />
            <Box>
              {/*<IconButton onClick={() => props.setOpen(false)}>
                <ClearRoundedIcon fontSize="small" />
              </IconButton>*/}
            </Box>
          </Stack>

          <Typography variant="h4" color={"primary"} mb={1}>
            {text.cookieMenuTitle}
          </Typography>
          <Typography variant="body2" textAlign={"justify"} mb={1}>
            {text.cookieMainText}{" "}
            <MuiLink
              underline="always"
              color={"secondary.light"}
              href={language == "fr" ? "fr/cookiePolicy" : "cookiePolicy"}
            >
              <Typography
                variant="body2"
                color={"secondary.light"}
                display={"inline"}
              >
                {text.moreInfoLink}
              </Typography>
            </MuiLink>
          </Typography>
          <ColorButton
            onClick={handleAcceptAll}
            variant="contained"
            sx={{
              textColor: "#000000",
              fontFamily: "GraphikSemiBold",
              fontWeight: "bold",
              fontSize: "16px",
              spacing: "1px",
              mb: 2,
            }}
          >
            {text.acceptAll}
          </ColorButton>
          <Typography variant="h4" color={"primary"} mb={0.5}>
            {text.manageCookiesTitle}
          </Typography>
          <FormProvider {...form}>
            <form>
              <Stack spacing={0.01}>
                <Box>
                  <CheckBoxInput
                    disabled
                    label={
                      <Typography color="primary">
                        {text.mustHaveCookie}
                      </Typography>
                    }
                    defaultValue={true}
                    name="needed"
                  />

                  <Typography
                    variant="body2"
                    textAlign={"justify"}
                    pl={4.5}
                    mb={1}
                  >
                    {text.mustHaveTextBody}
                  </Typography>
                </Box>
                <hr />
                <Box>
                  <CheckBoxInput
                    label={
                      <Typography color="primary">
                        {text.performanceCookie}
                      </Typography>
                    }
                    defaultValue={defaults.performance}
                    name="performance"
                  />
                  <Typography
                    variant="body2"
                    textAlign={"justify"}
                    pl={4.5}
                    mb={1}
                  >
                    {text.performanceTextBody}
                  </Typography>
                </Box>
                <hr />
                <Box>
                  <CheckBoxInput
                    label={
                      <Typography color="primary">
                        {text.functionalCookie}
                      </Typography>
                    }
                    defaultValue={defaults.functional}
                    name="functional"
                  />
                </Box>
                <Typography
                  variant="body2"
                  textAlign={"justify"}
                  pl={4.5}
                  mb={1}
                >
                  {text.functionalTextBody}
                </Typography>
                <hr />
                <Box>
                  <CheckBoxInput
                    label={
                      <Typography color="primary">
                        {text.targetAudienceCookie}
                      </Typography>
                    }
                    defaultValue={defaults.targetAudiance}
                    name="targetAudiance"
                  />

                  <Typography
                    variant="body2"
                    textAlign={"justify"}
                    pl={4.5}
                    mb={1}
                  >
                    {text.targetAudienceTextBody}
                  </Typography>
                </Box>
                <hr />
                <Box>
                  <CheckBoxInput
                    label={
                      <Typography color="primary">
                        {text.socialMediaCookie}
                      </Typography>
                    }
                    defaultValue={defaults.socialMedia}
                    name="socialMedia"
                  />

                  <Typography
                    variant="body2"
                    textAlign={"justify"}
                    pl={4.5}
                    mb={1}
                  >
                    {text.socialMediaTextBody}
                  </Typography>
                </Box>
                <hr />
              </Stack>
              <ColorButton
                variant="contained"
                sx={{
                  textColor: "#000000",
                  fontFamily: "GraphikSemiBold",
                  fontWeight: "bold",
                  fontSize: "16px",
                  spacing: "1px",
                  mb: 2,
                  mt: 2,
                }}
                onClick={form.handleSubmit(handleCookieSubmit)}
              >
                {text.confirmSelection}
              </ColorButton>
            </form>
          </FormProvider>
        </Container>
      </Paper>
    </Dialog>
  );
}
