import {
  FormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  FormGroup,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
  FormControl,
} from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import React, { ReactElement } from "react";
import { useFormContext, Controller, RegisterOptions } from "react-hook-form";

export default function CheckboxGroup(props: {
  name: string;
  defaultValue: boolean;
  disabled?: boolean;
  label: ReactElement;
  rules?: RegisterOptions;
  muiFormControlLabelProps?: Omit<
    MuiFormControlLabelProps,
    "control" | "label"
  >;
  muiCheckboxProps?: MuiCheckboxProps;
}) {
  const [checked, setChecked] = React.useState(props.defaultValue);

  const form = useFormContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={form.control}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl error={fieldState.error ? true : false}>
            <FormControlLabel
              label={props.label}
              {...props.muiFormControlLabelProps}
              control={
                <MuiCheckbox
                  checked={checked}
                  value={checked}
                  disabled={formState.isSubmitting}
                  {...props.muiCheckboxProps}
                  onChange={(event) => {
                    if (props.disabled) {
                      return;
                    }
                    handleChange(event);
                    return field.onChange(event.target.checked);
                  }}
                />
              }
            />
            {fieldState.error && (
              <FormHelperText
                error
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <ReportProblemRoundedIcon sx={{ mr: 1 }} fontSize="small" />
                    {fieldState.error?.message}
                  </>
                }
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
}
