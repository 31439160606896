import "../styles/globals.css";
import "../public/fonts/fonts.css";
import type { AppProps } from "next/app";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "@styles/Theme";
import { MDXProvider } from "@mdx-js/react";
import mdxComponents from "@styles/mdxComponents";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import Navbar from "@client/components/navigation/Navbar";
import CookieModal from "@client/components/CookieModal";
import Footer from "@client/components/Footer";
import Cookies from "js-cookie";

function MyApp({ Component, pageProps }: AppProps) {
  const [cookieModalOpen, setCookieModalOpen] = useState<boolean>(false);
  //cookielogic
  const preferenceCookie = Cookies.get("OptanonToolsConsent"); // => 'value'
  useEffect(() => {
    if (!preferenceCookie) {
      setCookieModalOpen(true);
    }
  }, []);
  return (
    <>
      <React.Fragment>
        <Head>
          <title>pensioenoptimalisatie</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>
        <MDXProvider components={mdxComponents}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar />
            <Component {...pageProps} />
            <CookieModal open={cookieModalOpen} setOpen={setCookieModalOpen} />
            <Footer setCookieModalOpen={setCookieModalOpen} />
          </ThemeProvider>
        </MDXProvider>
      </React.Fragment>
    </>
  );
}

export default MyApp;
